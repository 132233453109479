import fetch from '../utils/fetch'

// tdk全局配置获取
export function queryCategoryList (query) {
  return fetch({
    url: '/yzh/queryCategoryList',
    method: 'post',
    params: query
  })
}

// tdk全局配置保存
export function queryAddressProvince (query) {
  return fetch({
    url: '/yzh/queryAddressProvince',
    method: 'post',
    params: query
  })
}
export function queryBrandList (query) {
  return fetch({
    url: '/yzh/queryBrandList',
    method: 'post',
    params: query
  })
}
export function queryGoodsList (query) {
  return fetch({
    url: '/yzh/queryGoodsList',
    method: 'post',
    params: query
  })
}
export function queryGoodsListByCondition (query) {
  return fetch({
    url: '/yzh/queryGoodsListByCondition',
    method: 'post',
    params: query
  })
}
export function queryGoodsDetail (query) {
  return fetch({
    url: '/yzh/queryGoodsDetail',
    method: 'post',
    params: query
  })

}
export function postGoodsDetail (query) {
    return fetch({
      url: '/yzh/postGoodsDetail',
      method: 'post',
      params: query
    }) 
}
export function postOrderDetail (query) {
    return fetch({
      url: '/yzh/postOrderDetail',
      method: 'post',
      params: query
    })
}



